body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, .App, #root {
  min-height: 100%;
}

html, body {
  margin: 0;
  height: 100%;
}
.fill-height {
  min-height: 100%;
  height:auto !important; /* cross-browser */
  height: 100%; /* cross-browser */
}

#root > [data-reactroot] { height: 100vh }


.figure img {
  max-width: 100%;
  height: auto;
}