
.container {
  max-width: 720px;
  margin: auto;
}


.dropzone {
  border: 2px dashed #ddd;
  border-radius: 5px;
  background-color: #f7f7f7;
  padding: 50px;
  text-align: center;
}

.Reader {
  flex-direction: row;
  padding: 0px;
}


.App-theme-dark {
  background-color: #333;
  color: #fff;
}

.Reader-body {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: auto;
  max-width: 600px;
  padding: 20px;

  /* Reading sans friendly font */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  /* Font size */
  font-size: 1rem;

  /* Line height */
  line-height: 1.5;

  /* Letter spacing */
  letter-spacing: 0.01em;

  /* Text color */
  /* color: #333; */

}


.Reader-nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  padding: 20px;
  margin-top: 150px;
}

.Reader-nav ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.Reader-nav ol li {
  padding: 0 20px;
  margin: 0;
  margin-top: 10px;
}

.Reader-nav ol li a {
  color: #333;
}

.Reader-body a {
  text-decoration: underline;
  color: #333;
}

.App-theme-dark .Reader-body a {
  color: #fff;
}

header {
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 20px 10px;
  max-width: 600px;
  background-color: #eee;

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.App-theme-dark header {
  background-color: #555;
}

header h1 {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  font-weight: 400;
}

header nav {
  margin: 0;
  padding: 0;
}

header nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
}

header nav ul li {
  padding: 0;
  margin: 0;
}

header nav ul li a {
  text-decoration: none;
  color: #333;
  padding: 20px;
}

.App-theme-dark header nav ul li a {
  color: #fff;
}


.Reader-references ol {
  list-style: decimal;
  padding: 0;
  margin: 0;
}

.Reader-references ol li {
  padding: 0 20px;
  margin: 0;
  margin-top: 10px;
}